/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
label {
  line-height: 32px;
  vertical-align: middle;
  margin-right: 2px;
  color: #1890ff;
  white-space: nowrap;
}
.dropdown-list {
  width: 130px;
}
.input-textbox {
  width: 250px;
}
.blue-text {
  color: #1890ff;
}
