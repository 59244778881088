/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.claim_payment-list::v-deep .service-card.mycard {
  padding: 8px 0 ;
  width: 100%;
  border: none;
  max-width: 485px;
}
.claim_payment-list-topic {
  position: relative;
  margin-bottom: 8px;
  display: flex;
}
.claim_payment-list-topic .topic {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  margin-right: 10px;
}
.claim_payment-list-topic .see-more {
  font-size: 0.95em;
  text-decoration: underline dashed;
  align-self: center;
}
.mycard-ant-list.ant-list::v-deep {
  min-height: 80px;
}
.mycard-ant-list.ant-list::v-deep .ant-empty {
  margin: 8px 0;
}
.mycard-ant-list.ant-list::v-deep > .ant-spin-nested-loading {
  min-height: 80px;
}
