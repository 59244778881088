/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-search-pane {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  margin-bottom: 24px;
}
.drone-search-pane > label {
  line-height: 32px;
  vertical-align: middle;
  margin-right: 2px;
  color: #1890ff;
}
.drone-search-pane .create-pane {
  display: inline-block;
  margin-left: 16px;
}
.mobile .drone-search-pane .create-pane {
  display: block;
  margin-left: 0;
  margin-top: 8px;
}
