.dashboard-pane {
	box-shadow: @shadow-1-right;
	padding : 16px 32px;
	background : @white;
	margin-bottom : 24px;
	.mobile & ,.tablet & {
		padding : 16px;
	}
}

.dashboard-action-pane {
	margin-top : 24px;
	margin-bottom : 12px;
	text-align : right;
}

.dashboard-data-pane {
	display : flex;
	align-items : stretch;
	align-content : flex-start;
	padding-top : 24px;
	padding-bottom : 16px;
	margin : 0 -16px;
	width : auto;
	.ticket-data-list , .claim-data-list{
		border-right : 1px dashed @border-color-base;
		padding : 0 16px;
		min-width: 0;
		&:last-child {
			border-right-style : none;
		}
	}
	.mobile & ,.tablet & {
		display : block;
		margin : 0;
		.ticket-data-list , .claim-data-list {
			width : 100%;
			margin : 0 auto 16px;
			max-width: 500px;
			padding : 8px 16px;
			box-shadow : @shadow-2;
			border-right : none;
		}
	}
	.data-remark {
		font-size : 0.9em;
		color : @text-muted;
		margin-top : 16px;
		text-align : right;
	}
}
.service-pane {
	background-image : url("@{image-dir}/bg_service.png");
	background-repeat: no-repeat;
  background-position: right top;
	background-size: 200px 200px;
	min-height : 250px;
}
.ticket-pane {
	background-image : url("@{image-dir}/bg_ticket.png");
	background-repeat: no-repeat;
  background-position: right top;
	background-size: 200px 200px;
	min-height : 250px;
}

.claim-pane {
	background-image : url("@{image-dir}/bg_claim.png");
	background-repeat: no-repeat;
  background-position: right top;
	background-size: 200px 137px;
	min-height : 250px;
}
.claim-payment-pane {
	background-image : url("@{image-dir}/bg_claim_payment.png");
	background-repeat: no-repeat;
  background-position: right top;
	background-size: 200px 137px;
	min-height : 250px;
}
