/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-lite-list-card {
  padding: 8px 0;
  width: 100%;
}
.ticket-lite-list-first {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-lite-list-first .drone-name {
  display: inline-block;
  padding: 2px 6px;
  font-size: 0.75em;
  border: 1px dashed #8fb5ad;
  color: #14837B;
  margin-right: 2px;
  border-radius: 8px;
}
.ticket-lite-list-first .drone-name-general {
  display: inline-block;
  padding: 2px 6px;
  font-size: 0.75em;
  background-color: #ad8b00;
  color: #fff;
  margin-right: 2px;
  border-radius: 8px;
}
.ticket-lite-list-first .topic-link {
  font-size: 0.95em;
}
.ticket-lite-list-detail {
  display: flex;
  flex-wrap: wrap;
  color: #8c8c8c;
  padding-top: 12px;
  align-content: flex-end;
}
.ticket-lite-list-detail .left {
  display: block;
  margin-right: 2px;
  align-self: flex-end;
}
.ticket-lite-list-detail .right {
  display: block;
  text-align: right;
  flex-grow: 1;
  align-self: flex-end;
}
.ticket-lite-list-detail .ticket-status-list {
  font-size: 0.9em;
}
.ticket-lite-list-detail .ticket-open-details {
  font-size: 0.8em;
}
.ticket-field-company {
  color: #14837B;
}
