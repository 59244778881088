/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-section {
  margin-bottom: 24px;
}
.mobile .service-section {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin: 0 auto 24px;
  max-width: 500px;
  padding: 8px 16px;
}
.service-section-divider {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 24px;
}
.mobile .service-section-divider {
  display: none;
}
.service-section-header {
  padding-bottom: 4px;
  margin-bottom: 8px;
}
.service-section-header label {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  margin-right: 5px;
}
.service-section-header .service-section-action {
  text-decoration: underline dashed;
}
.service-section-remark {
  font-size: 0.9em;
  color: #8c8c8c;
  margin-top: 8px;
}
