/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-card {
  border: 1px solid #d9d9d9;
  padding: 8px 12px;
  min-width: 350px !important;
  display: flex;
  flex-direction: column;
}
.service-row {
  zoom: 1;
}
.service-row::before,
.service-row::after {
  display: table;
  content: '';
}
.service-row::after {
  clear: both;
}
.service-row .left {
  float: left;
}
.service-row .right {
  float: right;
}
.service-card-header {
  margin-bottom: 4px;
}
.service-card-content {
  flex-grow: 1;
  min-height: 0;
  display: flex;
  flex-wrap: nowrap;
}
.service-card-content .row {
  margin-bottom: 2px;
}
.service-card-content .row label {
  color: #14837B;
}
.service-card-content .row label::after {
  content: ' :';
}
.service-detail {
  flex-grow: 1;
  min-width: 0;
  font-size: 0.95em;
}
.service-status-list {
  flex-basis: 160px;
  min-width: 160px;
  padding: 4px 0 8px 8px;
  border-left: 1px dashed #1890ff;
  font-size: 0.9em;
  text-align: right;
}
.service-field-status {
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 4px;
}
.service-field-no {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.1em;
  color: #14837B;
}
.service-field-drone {
  display: block;
  padding: 2px 6px;
  font-size: 0.85em;
  border: 1px dashed #8fb5ad;
  color: #14837B;
  border-radius: 8px;
  margin-top: 4px;
}
.service-field-company {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  text-align: right;
}
.text-detail {
  color: #8c8c8c;
  font-size: 0.8em;
}
@media (max-width: 575px) {
  .service-card {
    min-width: 100% !important;
  }
  .service-card-content {
    flex-direction: column;
  }
  .service-status-list {
    flex-basis: auto;
    min-width: 100% ;
    margin-top: 8px;
    padding: 8px 0 0 0;
    border-top: 1px dashed #1890ff;
    border-left: none;
    text-align: left;
  }
}
