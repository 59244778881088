/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dashboard-pane {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  padding: 16px 32px;
  background: #fff;
  margin-bottom: 24px;
}
.mobile .dashboard-pane,
.tablet .dashboard-pane {
  padding: 16px;
}
.dashboard-action-pane {
  margin-top: 24px;
  margin-bottom: 12px;
  text-align: right;
}
.dashboard-data-pane {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  padding-top: 24px;
  padding-bottom: 16px;
  margin: 0 -16px;
  width: auto;
}
.dashboard-data-pane .ticket-data-list,
.dashboard-data-pane .claim-data-list {
  border-right: 1px dashed #d9d9d9;
  padding: 0 16px;
  min-width: 0;
}
.dashboard-data-pane .ticket-data-list:last-child,
.dashboard-data-pane .claim-data-list:last-child {
  border-right-style: none;
}
.mobile .dashboard-data-pane,
.tablet .dashboard-data-pane {
  display: block;
  margin: 0;
}
.mobile .dashboard-data-pane .ticket-data-list,
.tablet .dashboard-data-pane .ticket-data-list,
.mobile .dashboard-data-pane .claim-data-list,
.tablet .dashboard-data-pane .claim-data-list {
  width: 100%;
  margin: 0 auto 16px;
  max-width: 500px;
  padding: 8px 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-right: none;
}
.dashboard-data-pane .data-remark {
  font-size: 0.9em;
  color: #8c8c8c;
  margin-top: 16px;
  text-align: right;
}
.service-pane {
  background-image: url("~@assets/images/bg_service.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 200px 200px;
  min-height: 250px;
}
.ticket-pane {
  background-image: url("~@assets/images/bg_ticket.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 200px 200px;
  min-height: 250px;
}
.claim-pane {
  background-image: url("~@assets/images/bg_claim.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 200px 137px;
  min-height: 250px;
}
.claim-payment-pane {
  background-image: url("~@assets/images/bg_claim_payment.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 200px 137px;
  min-height: 250px;
}
.dashboard .ticket-data-list {
  flex-grow: 50%;
  width: 50%;
}
.dashboard .claim-data-list {
  flex-grow: 50%;
  width: 50%;
}
.dashboard .claim-data-list .data-remark {
  text-align: left;
}
