/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.status-icon {
  font-size: 0.6em;
  height: 0.8em;
  vertical-align: middle;
}
.tooltip-reason {
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 0.9em;
  padding-right: 12px;
}
.tooltip-reason > label {
  text-decoration: underline double;
}
.tooltip-reason > div {
  margin-top: 2px;
  margin-left: 12px;
}
