/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-stat-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 24px;
}
.stat-card {
  flex-basis: 300px;
  min-width: 0;
  padding: 28px 32px;
  background: #fff;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.mobile .stat-card {
  padding: 28px 16px;
  font-size: 0.9em;
  border-right: 1px solid #d9d9d9;
}
.mobile .stat-card:last-child {
  border-right: none;
}
.stat-title {
  color: #8c8c8c;
  font-size: 0.95em;
  margin-bottom: 8px;
}
.stat-value {
  margin-bottom: 4px;
}
.stat-value .value {
  color: #14837B;
  font-size: 2em;
  margin-right: 4px;
}
.mobile .stat-value .value {
  font-size: 1.7em;
  margin-right: 0;
}
.stat-value .suffix {
  color: #8c8c8c;
  font-size: 0.9em;
}
.stat-previous {
  color: #8c8c8c;
  font-size: 0.85em;
}
.mobile .stat-previous {
  font-size: 0.75em;
}
